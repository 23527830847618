<template>
    <div class="row g-0">
        <section class="main col-md-6 offset-md-6">
            <div class="row g-0">
                <div class="col">
                    <div class="text-wrapper">
                        <div class="row">
                            <div class="col-md-9">
                                <h2>El desarrollo integral de una comunidad depende del desarrollo de su gente.</h2>
                            </div>
                        </div>
                        <div class="black-container">
                            <div class="row">
                                <div class="col-md-9">
                                    El objetivo de Kn·U es ofrecer una plataforma de desarrollo integral para crear un entorno de justicia social, responsabilidad ambiental y plenitud emocional; a través de educación accesible y de excelente calidad.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <card>
                <div class="row">
                    <div class="col-md-9">
                        <separator />
                        <p style="margin-top: 0;">ENTRAR A Kn·U</p>
                        <form @submit.prevent="login">
                        <p class="error" v-if="errors.login">Usuario o contraseña incorrecta. Por favor, intente de nuevo.</p>
                        <Textfield :disabled="loadingLogin" :error="errors.user" v-model="user" placeholder="USUARIO" />
                        <Textfield :disabled="loadingLogin" :error="errors.pass" v-model="password" placeholder="CONTRASEÑA" type="password" />
                        <div class="row">
                            <div class="col">
                                <Button type="submit" :loader="loadingLogin">Iniciar Sesión</Button>
                            </div>
                        </div>
                        </form>
                        <!-- <separator />
                        <p>O REGISTRATE PARA OBTENER TU USUARIO</p>
                        <Textfield v-model:value="email" placeholder="EMAIL" />
                        <Button @click="register">REGISTRARME</Button> -->
                    </div>
                </div>
            </card>
        </section>
    </div>
</template>
<script>
export default {
    data() {
        return {
            user: '',
            password: '',
            email: '',
            loadingLogin: false,
            errors: {
            }
        }
    },
    methods: {
        canSubmitLogin() {
            if (this.loadingLogin) return false;

            this.errors.user  = this.user.trim() == '';
            this.errors.pass  = this.password.trim() == '';
            return !this.errors.user && !this.errors.pass;
        },
        lockLogin(){
            this.loadingLogin = true;
            this.errors.login = false;
        },
        unlockLogin(){
            this.loadingLogin = false;
        },
        async login() {
            if (!this.canSubmitLogin()){
                return;
            }
            this.lockLogin();

            this.$store.dispatch('kuAuth', {user: this.user, password: this.password})
            .then(() => {
                const isAuth = this.$store.getters.isFullAuth;
                if (isAuth) //hasRegistered
                    this.$router.push('/my-programs');
                else 
                    this.$router.push('/terms');
            }).catch((error) => {
                console.error(error);
                this.unlockLogin();
                this.errors.login = true;
            });
        },
        async register() {
            this.$router.push('/terms')
        }
    }
}
</script>
<style lang="scss" scoped>
button {
    float: right;
    margin: 15px 0;
}
h2 {
    text-align: left;
}
.text-wrapper {
    // margin-top:150px;
    .black-container{
        font-family: 'Montserrat-Regular';
        font-size: 22px;
        line-height: 30px;
        background: linear-gradient(0deg, rgba(0,0,0,.7) 0%, rgba(0,0,0,0) 100%);
        padding: 20px 0;
        padding-left: 30px;
        margin-bottom: 0;
    }
    h2{
        padding-left: 30px;
    }
}

</style>

